import Logger from 'utilities/logger';

export const LAST_LOGIN = 'mycncLastLogin';
export const DISMISSED = 'mycncProfileVerificationDismissed';

const isFunction = fn => typeof fn === 'function';

const isAvailable = storage =>
  isFunction(storage.getItem) &&
  isFunction(storage.setItem) &&
  isFunction(storage.removeItem);

const cookieStorage = {
  getItem: key => {
    let cookieObj = {};
    const cookieArr = document.cookie.split(';');
    cookieArr.forEach(cookie => {
      const [key, val] = cookie.split('=');
      cookieObj = { ...cookieObj, [key]: val };
    });
    const { [key]: cookie } = cookieObj;
    return cookie || null;
  },

  setItem: (key, val) => (document.cookie = `${key}=${val}; expires=;`),

  removeItem: key => (document.cookie = `${key}=; expires=0;`),
};

const getStorage = () => {
  const logger = Logger('Storage');
  if (window.localStorage && isAvailable(window.localStorage)) {
    logger.debug({ storage: 'LOCAL' });
    return window.localStorage;
  }

  if (window.sessionStorage && isAvailable(window.sessionStorage)) {
    logger.debug({ storage: 'SESSION' });
    return window.sessionStorage;
  }

  logger.debug({ storage: 'COOKIE' });
  return cookieStorage;
};

export default getStorage;
