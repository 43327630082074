import { gql } from '@apollo/client';

export default gql`
  query getUserShortcuts($projectId: String) {
    getCurrentUser {
      shortcuts(projectId: $projectId) {
        id
        name
        type
        pageLink
        filePath
        fileName
        createdAt
        updatedAt
      }
    }
  }
`;
