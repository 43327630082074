import { gql } from '@apollo/client';

export default gql`
  query Batches($pid: String) {
    batches(pid: $pid) {
      batchHtgId
      batchNumber
      batchName
      approvers {
        firstName
        lastName
        email
        role
        roleDisplay
        approverLevel
      }
      dateAvailable
      timecardsInBatchCount
      lowestTimecardHtgId
      status
      timecardsInStatusCount
      viewTimecardsUrl
    }
  }
`;
