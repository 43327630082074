import { gql } from '@apollo/client';

export default gql`
  query BatchReminders($htgId: String, $crews: Boolean, $heads: Boolean) {
    batchReminders(htgId: $htgId, crews: $crews, heads: $heads) {
      type
      toEmail
      toFirstName
      toLastName
      projectName
      viewTimecardsUrl
      timecards {
        entryHeaderId
        firstName
        lastName
        status
        statusDisplay
        weekending
        departmentName
      }
    }
  }
`;
