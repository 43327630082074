import { useState, useEffect } from 'react';
import AuthenticationContext from './Context';
import Loader from 'components/Loader';
import Okta from 'providers/Okta';
import Tokens from 'providers/Tokens';
import validateFromURI, { validateInnerRoute } from 'utilities/validateFromURI';
import getStorage from 'utilities/getStorage';
import Logger from 'utilities/logger';

import { LAST_LOGIN } from 'utilities/getStorage';

const INITIAL = {
  permissions: [],
  loading: true,
  login: false,
  user: null,
};

const REDIRECT_1099 =
  process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? 'https://castandcrew.my.site.com/1099Services/login?so=00Di0000000aHzN'
    : 'https://castandcrew--uat.sandbox.my.site.com/1099services/login?so=00DOz0000000LIz';

const Provider = props => {
  const { children } = props;

  const auth = Okta();
  const storage = getStorage();
  const logger = Logger('authentication');
  const [state, setState] = useState(INITIAL);

  const update = data => setState(old => ({ ...old, ...data }));

  const refreshPermissions = async () => {
    auth.client.tokenManager.remove('idToken');
    const response = await auth.idToken();
    const {
      claims: {
        mycncScopes: permissions = [],
        family_name: lastName,
        given_name: firstName,
        preferred_username: email,
        sub: uid,
        terms_group: terms,
        mpn_group: mpn,
        startplus,
      } = {},
    } = response || {};
    const env = process.env.REACT_APP_ENVIRONMENT || 'local';
    const hoursplus = response?.claims?.[`hoursplus_${env}`];
    const idp = await auth.isIDP(email);
    const groups = { terms, mpn, startplus, hoursplus };
    const user = { uid, firstName, lastName, email, groups, idp };

    if (Array.isArray(startplus) && startplus.length > 0) {
      permissions.push('startplus:access');
    }
    if (Array.isArray(hoursplus) && hoursplus.length > 0) {
      permissions.push('hoursplus:access');
    }

    return { user, permissions };
  };

  const refresh = async () => {
    const response = await refreshPermissions();
    update(response);
  };

  const requestToken = async scope => {
    const token = await Tokens.request(auth, scope);
    return token;
  };

  const logout = async () => {
    update(INITIAL);
    if (window.pendo) window.pendo.clearSession();
    logger.info('Logged out');
    Tokens.clear();
    storage.removeItem(LAST_LOGIN);
    await auth.logout();
    location.reload(true);
  };

  const dashboardLogout = async () => {
    update(INITIAL);
    Tokens.clear();
    auth.clearToken();
    sessionStorage.clear();
  };

  useEffect(() => {
    if (auth.client.token.isLoginRedirect()) {
      // this would be an ugly hack until parseFromUrl() works properly
      // response = await okta.token.parseFromUrl();
      logger.debug('Is Login Redirect');
      window.location.replace('/');
      return;
    }

    const processAuth = async () => {
      const data = { loading: false };
      data.login = await auth.checkSession();
      logger.debug(`Checking session: ${data.login}`);

      if (data.login) {
        const response = await refreshPermissions();
        logger.debug({ refreshPermissions: response });

        data.permissions = response.permissions;
        data.user = response.user;

        if (!storage.getItem(LAST_LOGIN)) {
          storage.setItem(LAST_LOGIN, new Date().toISOString());
        }
      }

      return data;
    };

    processAuth()
      .then(data => {
        if (data.login) {
          const redirect1099 = storage.getItem('redirect1099');
          storage.removeItem('redirect1099');
          if (redirect1099) {
            logger.debug('Redirecting to 1099 Center', { redirect1099 });
            storage.setItem('redirecting', 'true');
            window.location.replace(REDIRECT_1099);
            data.loading = true;
          } else {
            const redirect = storage.getItem('redirect');
            storage.removeItem('redirect');
            if (redirect && validateFromURI(redirect)) {
              logger.debug('Redirecting fromURI', { redirect });
              storage.setItem('redirecting', 'true');
              window.location.replace(redirect);
              data.loading = true;
            } else if (redirect && validateInnerRoute(redirect)) {
              logger.debug('Redirecting inner route', { redirect });
              storage.setItem('redirecting', 'true');
              window.location.replace(redirect);
              data.loading = true;
            }
          }
        }
        update(data);
      })
      .catch(err => logger.error(err));
  }, []);

  if (state.loading) {
    return <Loader fullScreen withLogo />;
  }

  const value = {
    ...state,
    auth,
    storage,
    logout,
    refresh,
    requestToken,
    dashboardLogout,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default Provider;
