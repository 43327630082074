export default {
  VERIFY_INFORMATION_BUTTON: 'Verify information',
  VERIFY_INFORMATION_CONFIRMED: 'Confirmed',
  VERIFY_INFORMATION_LAST_UPDATED: 'Last updated: <<var1>>',
  VERIFY_INFORMATION_SUCCESS: 'Successfully verified profile',
  VERIFY_INFORMATION_SUCCESS_DESCRIPTION:
    'We will ask you to update this information again every 6 months.',
  VERIFY_INFORMATION_FAILED: 'Failed verifying profile',
  VERIFY_INFORMATION_FAILED_DESCRIPTION:
    'Something went wrong. Please try again.',
  VERIFY_MODAL_TITLE: 'Action required: Verify your personal info',
  VERIFY_MODAL_CONTENT: `To maintain accurate records and avoid payment delays,
    please review and verify your personal details, including your address and
    direct deposit information. This verification is required every 6 months.`,
  VERIFY_MODAL_DISMISS_MESSAGE:
    'This message can only be dismissed once. Please verify now.',
  VERIFY_MODAL_CANCEL: 'Dismiss',
  VERIFY_MODAL_SEND: 'Review and verify now',
};
