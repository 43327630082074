import { gql } from '@apollo/client';

export default gql`
  query PendingOffers($projectId: String) {
    pendingHires(projectId: $projectId) {
      id
      crewMember {
        id
        email
        firstName
        fullName
        lastName
        middleName
      }
      crewStartDate
      currency
      currentApprovers {
        id
        approvers {
          id
          email
          firstName
          fullName
          lastName
          middleName
          jobTitle
        }
        name
        position
        workflowId
      }
      dailyRate
      department
      goToOffer
      hourlyRate
      jobTitle
      projectName
      projectStartDate
      stateW4
      status
      statusDisplay
      statusColor
      type
      viewAllOffers
      weeklyRate
    }
    pendingI9s(projectId: $projectId) {
      id
      crewMember {
        id
        email
        firstName
        fullName
        lastName
        middleName
      }
      crewStartDate
      currency
      currentApprovers {
        id
        approvers {
          id
          email
          firstName
          fullName
          lastName
          middleName
          jobTitle
        }
        name
        position
        workflowId
      }
      dailyRate
      department
      goToOffer
      hourlyRate
      jobTitle
      projectName
      projectStartDate
      stateW4
      status
      statusDisplay
      statusColor
      type
      viewAllOffers
      weeklyRate
    }
  }
`;
