import { gql } from '@apollo/client';

export default gql`
  query getLastSendReminders($projectId: String) {
    dashboard {
      sentReminders(projectId: $projectId) {
        id
        type
        reference
        selected
        updatedAt
      }
    }
  }
`;
