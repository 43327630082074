import { gql } from '@apollo/client';

export default gql`
  query PendingOfferTasks($projectId: String) {
    pendingOfferTasks(projectId: $projectId) {
      pendingApproval
      pendingApprovalUrl
      pendingI9Verification
      pendingI9VerificationUrl
      rejected
      rejectedUrl
    }
  }
`;
