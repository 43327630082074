import { gql } from '@apollo/client';

export default gql`
  query ProjectReminders(
    $htgId: String
    $previous: Boolean
    $current: Boolean
  ) {
    projectReminders(htgId: $htgId, previous: $previous, current: $current) {
      type
      toEmail
      toFirstName
      toLastName
      projectName
      viewTimecardsUrl
      timecards {
        entryHeaderId
        firstName
        lastName
        status
        statusDisplay
        weekending
        departmentName
      }
    }
  }
`;
